import React from "react";

const Footer = () => {
  return (
    <div className=" pb-10 bg-[#060d13]">
      <div className="h-[.1rem] w-5/6 flex flex-col items-center bg-white mt-2 bg-gradient-to-r from-[#060d13] via-white to-[#060d13] opacity-20  "></div>
      <div className="flex flex-col items-center mt-10">
        <div className="w-full flex justify-between px-10 py-4 items-center">
          <div className="">
            <h3 className="font-bold bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 text-transparent bg-clip-text">
              DashinCodes
            </h3>
          </div>
          <a href="mailto:dashincodes@gmail.com">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-white opacity-70"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
