import React from "react";

const TechStack = () => {
  function scrollToElementById(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className="flex flex-col items-center justify-center bg-[#060d13]  w-full z-10 min-h-screen relative">
      <div className="grid md:grid-cols-3 grid-cols-2 md:w-5/6 md:gap-32 gap-12 relative md:mx-0 mx-4">
        <div className="flex flex-col items-start">
          <h4 className="md:text-3xl uppercase  font-bold bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text">
            My tech stack
          </h4>
          <h5 className="text-white md:text-lg mt-2 opacity-60">
            The websites, apps, and software I develop all use this tech stack
          </h5>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text pb-2">
            React.js
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text ">
            React Native
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text ">
            Javascript
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text">
            Tailwind
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text pb-2">
            MongoDB
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text">
            Node.js
          </h4>
        </div>
        <div>
          <h4 className=" font-bold md:text-5xl text-4xl bg-gradient-to-r from-blue-200  to-blue-400 text-transparent bg-clip-text">
            Express
          </h4>
        </div>
        <div>
          <button
            className="text-black font-medium text-lg bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 md:px-4 md:py-3 py-2 px-8 rounded-full md:w-3/4  

"
            onClick={() => {
              scrollToElementById("contact");
            }}
          >
            Get In Touch
          </button>
        </div>
      </div>
    </div>
  );
};

export default TechStack;
