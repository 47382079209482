import React from "react";
import headerMemoji from "../Images/dashin codes thumbs up memoji.png";

const Hero = () => {
  function scrollToElementById(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center h-[50rem]  ">
        {/* <div className=" bg-[#0B53C7] absolute h-[45rem] w-[45rem] rounded-full top-48  blur-3xl bg-opacity-50  left-40 z-10"></div>
        <div className=" bg-[#361694] absolute h-[45rem] w-[45rem] rounded-full top-48  blur-3xl bg-opacity-50 right-40 z-10  "></div> */}
        {/* <div className=" bg-white absolute h-[50rem] w-[50rem] rounded-full top-48  blur-3xl bg-opacity-10  z-10 "></div> */}
        <div className="flex flex-col items-center relative md:-mt-10 -mt-48 ">
          <div className="z-10 relative">
            <img src={headerMemoji} className="md:w-48 w-32 " alt="memoji" />
          </div>
          <h3 className="font-extrabold md:text-8xl text-6xl text-center leading-none bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 text-transparent bg-clip-text py-2 md:-mt-4 -mt-2 md:mx-0 mx-0">
            Web Developer <br />& Designer
          </h3>
          <h4 className="text-white text-xl md:mt-6 mt-2 md:w-3/5 md:px-0 px-4 text-center leading-9 font-light">
            Hey 👋. My name is Dashin Simmons, and I'm a designer and full stack
            developer. My mission is to create projects of exceptional quality.
          </h4>
          {/* button */}
          <div className="mt-8">
            <button
              onClick={() => {
                scrollToElementById("contact");
              }}
              className="text-black font-medium text-lg bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 px-4 py-3 rounded-full  shadow-xl shadow-indigo-500/70"
            >
              Let's Create Something Amazing
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#060d13]  min-h-screen w-full flex flex-col items-center pb-10 md:mt-20 -mt-36">
        <div className="grid md:grid-cols-2 gap-8 md:mx-10 justify-items-center md:w-5/6 w-full md:px-0 px-4 ">
          <div className="z-20  bg-violet-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-full flex flex-col items-center justify-center md:py-0 py-4 shadow-4xl  ">
            <div className="p-6  ">
              <img
                src="https://res.cloudinary.com/dzzilfb7l/image/upload/v1701102141/stid1skunbtooxcgdiid.webp"
                className="rounded-md"
                alt="site mockup"
              />
              <h2 className=" opacity-80 text-3xl uppercase font-bold mt-4 bg-gradient-to-r from-violet-200 via-violet-200 to-blue-400 text-transparent bg-clip-text">
                cookie palace
              </h2>
              <h3 className="text-white capitalize text-lg opacity-70 font-light h-14">
                design and web development for a nationwide direct to consumer
                cookie brand
              </h3>
            </div>
          </div>
          <div className="z-20  bg-violet-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-full flex flex-col items-center justify-center md:py-0 py-4 shadow-4xl">
            <div className="p-6">
              <img
                src="https://res.cloudinary.com/dzzilfb7l/image/upload/v1701105759/q1gqf6ejbmtc1qugahf0.png"
                className="rounded-md"
                alt="site mockup"
              />
              <h2 className=" opacity-80 text-3xl uppercase font-bold mt-4 bg-gradient-to-r from-violet-200 via-violet-200 to-blue-400 text-transparent bg-clip-text">
                foodie toolz
              </h2>
              <h3 className="text-white capitalize text-lg opacity-70 font-light h-14">
                web & software development for a innovative link in bio software
                for food trucks
              </h3>
            </div>
          </div>
          <div className="z-20 bg-violet-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-full flex flex-col items-center justify-center md:py-0 py-4 shadow-4xl">
            <div className="p-6">
              <img
                src="https://res.cloudinary.com/dzzilfb7l/image/upload/v1701106799/cfgrj74rbgnjyvaizjpx.png"
                className="rounded-md"
                alt="site mockup"
              />
              <h2 className=" opacity-80 text-3xl uppercase font-bold mt-4 bg-gradient-to-r from-violet-200 via-violet-200 to-blue-400 text-transparent bg-clip-text">
                sincere saints food truck
              </h2>
              <h3 className="text-white capitalize text-lg opacity-70 font-light h-14">
                design and web development for a Florida based food truck
                business
              </h3>
            </div>
          </div>
          <div className="z-20  bg-violet-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-full flex flex-col items-center justify-center md:py-0 py-4 shadow-4xl">
            <div className="p-6">
              <img
                src="https://ik.imagekit.io/jsklle928/Dental%20%20Dentist%20%20Website%20Mockup%20Design%20Cafe.png?updatedAt=1691627089085"
                className="rounded-md"
                alt="site mockup"
              />
              <h2 className=" opacity-80 text-3xl uppercase font-bold mt-4 bg-gradient-to-r from-violet-200 via-violet-200 to-blue-400 text-transparent bg-clip-text">
                brightbite dentistry
              </h2>
              <h3 className="text-white capitalize text-lg opacity-70 font-light h-14">
                design and web development for a dental practice
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
