import React from "react";
import fistBumpMemoji from "../Images/dashincodesfistpump.png";

const About = () => {
  return (
    <div className="bg-[#060d13] min-h-screen relative flex flex-col items-center justify-center pt-12 md:pb-0 pb-32 w-full ">
      <div className="relative  w-full  ">
        <h4 className="text-center text-white uppercase text-xl font-bold tracking-wide">
          about me
        </h4>
        <div className="w-full flex flex-col items-center mt-4 ">
          <h3 className="md:text-7xl text-5xl md:px-0 px-4 text-center  md:font-extrabold font-bold md:tracking-normal tracking-normal md:leading-normal leading-normal md:w-4/6 bg-gradient-to-r from-violet-400 via-blue-100 to-violet-300 text-transparent bg-clip-text opacity-90 ">
            I'm a Full Stack Developer and Designer with a strong focus on clean
            design and responsive development.
          </h3>
        </div>
        <div className="absolute md:-bottom-10 -bottom-32 md:right-44 -right-10">
          <img src={fistBumpMemoji} alt="memoji" className="w-48" />
        </div>
      </div>
    </div>
  );
};

export default About;
