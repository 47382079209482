import "./App.css";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import NavBar from "./Components/NavBar";
import TechStack from "./Components/TechStack";

function App() {
  return (
    <div className="bg-[#060d13] min-h-screen overflow-hidden">
      <div>
        <NavBar />
      </div>
      <div class>
        <Hero />
      </div>
      <div>
        <About />
      </div>
      <div className="flex flex-col items-center md:mt-5 mt-10  ">
        <div className="h-[.1rem] w-5/6 flex flex-col items-center bg-white mt-2 bg-gradient-to-r from-[#060d13] via-white to-[#060d13] opacity-20 "></div>
      </div>
      <div>
        <TechStack />
      </div>

      <div>
        <Contact />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
