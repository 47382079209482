import React from "react";
import contactMemoji from "../Images/contactdashincodesmemoji.png";
import deniedMemoji from "../Images/nomemojidashincodes.png";
import successMemoji from "../Images/wavememojidashincodes.png";
import { useState } from "react";
import axios from "axios";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("");
  const [found, setFound] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [confetti, setConfetti] = useState(false);
  const [budget, setBudget] = useState("");
  const [timeFrame, setTimeFrame] = useState("");

  const { width, height } = useWindowSize();

  const memoji = {
    neutral: contactMemoji,
    success: successMemoji,
    fail: deniedMemoji,
  };

  async function submitForm() {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !service ||
      !found ||
      !budget ||
      !timeFrame
    ) {
      setError("Please enter all fields");
      return;
    }

    try {
      const { data } = await axios.post(
        `https://sheetdb.io/api/v1/u93i77pvd5boa`,
        {
          firstName,
          lastName,
          email,
          service,
          found,
          budget,
          timeFrame,
        }
      );
      setSubmitted(true);
      setSuccess(true);
      setError("");
      setConfetti(true);

      setTimeout(() => {
        setConfetti(false);
      }, 7000);

      console.log(data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      className="bg-[#060d13] min-h-screen flex flex-col items-center relative md:mt-0 md:pt-10 pt-10 -mt-12 z-10"
      id="contact"
    >
      {confetti && <Confetti width={width} height={height} />}
      <div className="relative flex flex-col items-center  bg-violet-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10  h-full justify-center md:py-12 py-12 shadow-4xl md:w-5/6 w-[90%] md:px-0 px-4 ">
        <div className=" flex flex-col items-center">
          <h4 className="bg-gradient-to-r from-violet-100  to-violet-300 text-transparent bg-clip-text text-6xl font-black capitalize">
            let's chat
          </h4>
          <h4 className="text-center md:w-3/6 text-white opacity-70 md:text-xl text-lg mt-6  font-light">
            I'm excited to hear about the project that you have in mind.{" "}
            <span className="font-bold bg-gradient-to-r from-violet-100  to-violet-300 text-transparent bg-clip-text    ">
              I'm only one email away.
            </span>{" "}
            Let's talk about how we can collaborate and turn your idea into a
            reality. Talk soon!
          </h4>
        </div>
        {error ? (
          <div className="absolute md:bottom-0 md:right-20 bottom-0 -right-4">
            <img src={memoji.fail} alt="memoji" className="md:w-48 w-32" />
          </div>
        ) : success === true ? ( // Assuming 'success' is another state variable
          <div className="absolute md:bottom-0 md:right-20 bottom-0 -right-4">
            <img src={memoji.success} alt="memoji" className="md:w-48 w-32" />
          </div>
        ) : (
          <div className="absolute md:bottom-0 md:right-20 bottom-0 -right-4">
            <img src={memoji.neutral} alt="memoji" className="md:w-48 w-32" />
          </div>
        )}

        <div className="mt-6 md:w-2/5 w-full">
          <div className="gap-3 flex flew-wrap md:flex-nowrap w-full">
            <input
              required={true}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              className="bg-violet-400 capitalize bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 md:w-full w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
              placeholder="First Name"
            />
            <input
              required={true}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              className="bg-violet-400 capitalize bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 md:w-full w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="mt-6 md:w-2/5 w-full">
          <input
            required={true}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="bg-violet-400  bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
            placeholder="Email Address"
          />
        </div>
        <div className="mt-6 md:w-2/5 w-full">
          <input
            required={true}
            onChange={(e) => {
              setService(e.target.value);
            }}
            className="bg-violet-400  bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300 capitalize"
            placeholder="What Service Are You Interested In?? "
          />
        </div>
        <div className="mt-6 md:w-2/5 w-full">
          <input
            required={true}
            onChange={(e) => {
              setBudget(e.target.value);
            }}
            className="capitalize bg-violet-400  bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
            placeholder="What Is Your Budget For This Project? "
          />
        </div>
        <div className="mt-6 md:w-2/5 w-full">
          <input
            required={true}
            onChange={(e) => {
              setTimeFrame(e.target.value);
            }}
            className="bg-violet-400 capitalize bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
            placeholder="How Soon Are You Wanting To Get Started? "
          />
        </div>

        <div className="mt-6 md:w-2/5 w-full">
          <input
            required={true}
            onChange={(e) => {
              setFound(e.target.value);
            }}
            className="bg-violet-400 capitalize bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-10 w-full h-12 rounded-lg placeholder:text-zinc-400 px-2 text-zinc-300"
            placeholder="How Did You Find Me? "
          />
        </div>
        <div className="mt-6 -mb-4">
          <h3 className="text-red-400">{error}</h3>
        </div>
        <div className="md:w-2/5 w-full flex flex-col items-center mt-10">
          {submitted ? (
            <button
              type="submit"
              onClick={submitForm}
              className="text-black font-medium text-lg bg-gradient-to-r from-green-400 via-green-200 to-green-200 px-20 py-3 rounded-full   shadow-xl shadow-green-500/70

"
            >
              Success
            </button>
          ) : (
            <button
              onClick={submitForm}
              className="text-black font-medium text-lg bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 px-4 py-3 rounded-full   shadow-xl shadow-indigo-500/70

"
            >
              Get in Touch With Me
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
