import React from "react";

const NavBar = () => {
  function scrollToElementById(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-full flex justify-between px-10 py-4 items-center">
        <div className="">
          <h3 className="font-bold bg-gradient-to-r from-violet-400 via-violet-200 to-blue-200 text-transparent bg-clip-text">
            DashinCodes
          </h3>
        </div>
        <div>
          <button
            className="group relative grid overflow-hidden rounded-full px-8 py-3 shadow-[0_1000px_0_0_hsl(0_0%_20%)_inset] transition-colors duration-200"
            onClick={() => {
              scrollToElementById("contact");
            }}
          >
            <span>
              <span className="spark mask-gradient animate-flip before:animate-rotate absolute inset-0 h-[100%] w-[100%] overflow-hidden rounded-full [mask:linear-gradient(white,_transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
            </span>
            <span className="backdrop absolute inset-[2px] rounded-full  bg-black transition-colors duration-200 " />
            <span className="text z-10 text-[#cbd5e1]">Contact Me</span>
          </button>
        </div>
      </div>
      <div className="h-[.1rem] w-5/6 flex flex-col items-center bg-white mt-2 bg-gradient-to-r from-[#060d13] via-white to-[#060d13] opacity-20"></div>
    </div>
  );
};

export default NavBar;
